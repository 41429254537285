.rich-image {
  align-content: flex-end;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;

  .content {
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    padding: 2rem;
    transition: all 0.3s;
    width: 100%;
  }

  @include breakpoint(medium) {
    &:hover .content {
      transform: translateY(0%);
    }

    .content {
      background-color: rgba(0, 0, 0, 0.5);
      bottom: 0;
      color: #fff;
      padding: 2rem 4rem;
      position: absolute;
      transform: translateY(calc(100% - 10px));
      transition: all 0.3s;
      width: 100%;

      &:before {
        content: "===";
        left: 50%;
        top: 2px;
        line-height: 0;
        position: absolute;
        font-size: 1.4rem;
        font-weight: bold;
        color: #999;
      }

      p {
        margin: 0;
      }
    }
  }
}
