/* scss */

.sectie {
  background-color: $black;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: rem-calc(24);

  &__header {
    color: $white;
    text-transform: uppercase;
    font-family: $mondo-font;
    padding: 0 0.6rem;

    a, a:hover, a:focus{
      color:white;
    }
  }


}