/* scss */

.boxcrumb {
  background-color: $black;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: rem-calc(24);
  color: $white;
  text-transform: uppercase;
  font-family: $mondo-font;
  padding: 0 0.6rem;

  &__link {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  &__item {
    color: var(--mondogrijs);
  }
}
