/*  scss file  */

.footer {
  margin: 100px 0 0 0;

  a {
    display: block;
    color: #dc0d17;
    margin: 0;
    padding: .4375rem .875rem;
  }

  .quicklinks {
    margin: 0;
    padding: 0;

    a {
      text-transform: uppercase;

    }

    .item {
      margin: 0;
      list-style: none;
      padding: 0;

      a {
        padding-left: 0;
      }
    }

  }
  .relativeContainer {
    position: relative;
  }

  .copyright {
    // border: 1px solid #000;

    @media #{$medium-up} {
      position: absolute;
      bottom: 10px;
    }

  }
}