/*  scss file  */

.register {

  @include grid-column-position(center);

  @include breakpoint(medium) {
    @include grid-column-position(center);
    @include grid-column(6)
  }

  @include breakpoint(large) {
    @include grid-column-position(center);
    @include grid-column(6)
  }

}