/* scss */

.pageNav {
  color: var(--mondorood);
  display: flex;
  flex-wrap: nowrap;
  font-family: $mondo-font;
  font-size: 4rem;
  font-weight: bold;
  height: 40px;
  justify-content: space-between;
  line-height: 0;
  padding: 35px 0;
  pointer-events: none;
  position: -webkit-sticky;
  position: sticky;
  top: 50vh;
  width: 100%;
  z-index: 2;

  @include breakpoint(medium) {
    font-size: 7rem;
  }

  .top & a {
    color: var(--mondorood);
  }
  .groen & a {
    color: var(--mondogroen);
  }

  .rood & a {
    color: var(--mondozwart);
  }

  .blauw & a {
    color: var(--mondoblauw);
  }

  .next {
    pointer-events: all;
  }

  .prev {
    pointer-events: all;
  }
}
