/*  scss file  */

.blokgrid {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

.blokrow {
  position: relative;
  min-height: 200px;
  height: 100%;
  display: block;

  &:hover {
    .overlay {
      display: block;
    }
  }

  .blokimage {
    width: 100%;
    height: 200px;
    background: url() center no-repeat;
    background-size: cover;
    display: block;

    &:hover {
    }
  }

  .colored {
    position: absolute;
    top: 0;
    background: rgba(200, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    padding: 20px;
  }

  .blended {
    position: absolute;
    top: 0;
    background: center #c80000;
    background-blend-mode: multiply;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-size: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: none;

    h3 {
      font-size: 1.5rem;
      color: #fff;
      line-height: 1.75rem;

      a {
        color: $white;

        &:hover {
          color: var(--mondorood);
        }
      }
    }

    p {
      margin: auto 0;
      color: #fff;
      font-size: 0.875rem;
      text-align: center;
    }
    .datum {
      color: #fff;
    }
  }
}

.no-backgroundblendmode .blended {
  display: none;
}
