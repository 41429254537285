/*  scss file  */

.off-canvas {
  z-index: 1;
  margin-top:50px;

  a {
    color: white;
  }
  .mobilemenu {

    li {

      margin: 2rem 0;
    }

    a {
      color: $white;
      font-family: $mondo-font;
      text-transform: uppercase;
      font-size: 2rem;
    }
  }
}

.off-canvas.is-open {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}