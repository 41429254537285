/*  scss file  */

body {
  font-size: 18px;
}

h1,
h2 {
  font-weight: 700;
}

p {
  margin-bottom: 1.5rem;
}

.mondokop {
  font-family: $mondo-font;
  text-transform: uppercase;
}

hr {
  background-color: var(--mondorood);
  border: 0;
  color: var(--mondorood);
  height: 2px;
  width: 100%;
}

mark {
  padding: 0.125em 0.25em;
  background-color: #ffff00;
}
