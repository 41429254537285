/*  scss file  */

.speellijst {
  // th {
  //   text-align: left;
  // }
  display: grid;

  grid-template-columns: 1fr;

  @include breakpoint(medium) {
    grid-template-columns: auto auto auto auto 1fr;
  }

  .small {
    font-size: 16px;
    color: #999;
  }

  .agendarow {
    @include grid-row;
  }

  .headercell {
    display: none;
    font-weight: bold;

    @include breakpoint(medium) {
      display: block;
    }
  }

  .agendacell {
    float: left;
    padding-right: 0;
    padding-left: 0;
    min-width: 10%;

    &:nth-child(5n) {
      margin-bottom: 2rem;
    }

    @include breakpoint(medium) {
      padding-right: 3rem;
      &:nth-child(5n) {
        margin-bottom: 0;
      }
    }
  }

  .datum {
    // @include grid-column(6);

    // @include breakpoint(medium) {
    //   @include grid-column(2);
    // }
    // @include breakpoint(medium) {
    //   @include grid-column(2);
    // }
  }

  .tijd {
    // @include grid-column(6);
    // @include breakpoint(large) {
    //   @include grid-column(2);
    // }
    // @include breakpoint(medium) {
    //   @include grid-column(2);
    // }
  }

  .voorstelling {
    // @include grid-column();
    // @include breakpoint(large) {
    //   @include grid-column(2);
    // }
    // @include breakpoint(medium) {
    //   @include grid-column(2);
    // }
  }

  .theater {
    // @include grid-column();
    // @include breakpoint(large) {
    //   @include grid-column(2);
    // }
    // @include breakpoint(medium) {
    //   @include grid-column(2);
    // }
  }

  .plaats {
    // @include grid-column();
    // @include breakpoint(large) {
    //   @include grid-column(2);
    // }
    // @include breakpoint(medium) {
    //   @include grid-column(2);
    // }
  }
}
