/*  scss file  */
.pagetitle {
  padding-top: 70px;
}

.maincontent {
  padding: 0;
  font-size: 1.1rem;

  @include breakpoint(medium) {
    font-size: 1.1rem;
  }

  p,
  blockquote,
  ul,
  ol {
    margin: 0 5% 12px 5%;

    @include breakpoint(medium) {
      margin: 0 auto 12px auto;
    }
  }

  p {
    padding-bottom: 20px;
    max-width: 500px;
  }

  th {
    text-align: left;
  }

  h1 {
    //font-size: 2.2rem;

    @include breakpoint(medium) {
      // font-size: 4rem;
    }

    @include breakpoint(large) {
      // font-size: 5rem;
    }
  }

  figure {
    text-align: center;
    position: relative;
    color: var(--mondozwart);
    margin: 1rem 0;
  }

  figcaption {
    padding: 1rem 0;
  }

  .left50 {
    width: 100%;
    float: none;

    @include breakpoint(medium) {
      width: 50%;
      margin: 1rem 1rem 1rem 10%;
      float: left;
    }
  }

  .left33 {
    width: 100%;
    float: none;

    @include breakpoint(medium) {
      width: 33%;
      margin: 1rem 1rem 1rem 10%;
      float: left;
    }
  }

  .right50 {
    width: 100%;
    float: none;

    @include breakpoint(medium) {
      width: 50%;
      margin: 1rem 10% 1rem 1rem;
      float: right;
    }
  }

  .right33 {
    width: 100%;
    float: none;

    @include breakpoint(medium) {
      width: 33%;
      margin: 1rem 10% 1rem 1rem;
      float: right;
    }
  }
}
