/* scss */
.paginarij {
  background-repeat: repeat-y;
  background-size: 9px;
  border-top: 2px dotted #000;
  margin-bottom: 4rem;
  padding: 2rem 0;

  @include breakpoint(medium) {
    background-repeat: repeat;
    background-size: 1000px;
  }

  .content {
    background-color: rgba(255, 255, 255, 0.8);

    &:not(:last-of-type) {
      border-bottom: 2px solid var(--mondorood);
      margin-bottom: 100px;
      padding-bottom: 30px;
    }
  }

  &.top {
    background-image: url("/assets/img/mobydick/top-mobile.png");
    border-color: transparent;
    @include breakpoint(medium) {
      background-image: url("/assets/img/mobydick/top.png");
    }
  }

  &.groen {
    background-image: url("/assets/img/mobydick/groen-mobile.png");
    border-color: var(--mondogroen);
    @include breakpoint(medium) {
      background-image: url("/assets/img/mobydick/groen.png");
    }
  }

  &.rood {
    background-image: url("/assets/img/mobydick/rood-mobile.png");
    @include breakpoint(medium) {
      background-image: url("/assets/img/mobydick/rood.png");
    }
  }

  &.blauw {
    background-image: url("/assets/img/mobydick/blauw-mobile.png");
    border-color: var(--mondoblauw);
    @include breakpoint(medium) {
      background-image: url("/assets/img/mobydick/blauw.png");
    }
  }
}
