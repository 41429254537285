/*  scss file  */

.splash {
    height: 100%;

    .mondokop{
        font-family: $mondo-font;
        text-transform: uppercase;
        font-size: 3rem;


        @include breakpoint(medium) {
            font-size: 5rem;
        }

        @include breakpoint(large) {
            font-size: 5rem;
        }

    }

    .wrapper {
        height: 100%;
        background-image: url('/assets/images/default_bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .btn {
            font-size: rem-calc(42);
            position: absolute;

            @media #{$medium-up}{
                font-size: rem-calc(62);
            }

            @media #{$large-up}{
                font-size: rem-calc(82);
            }

            a {
                color: $white;
            }

            &.top {
                top: 20%;
                left: 20%;
            }

            &.bottom {
                bottom: 20%;
                right: 20%;
            }
        }
    }
}