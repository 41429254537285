/* scss */

.hero {

  &__background {
    background-size: cover;
    min-height: 150px;
    position: relative;

    @include breakpoint(large) {
      min-height: 300px;
    }

    @include breakpoint(medium) {
      min-height: 273px;
    }


    &.left {
      background-position-x: left;
    }

    &.center {
      background-position-x: center;
    }

    &.right {
      background-position-x: right;
    }

    &.top {
      background-position-y: top;
    }

    &.middle {
      background-position-y: center;
    }

    &.bottom {
      background-position-y: bottom;
    }
  }

  &__title {
    //background: rgb(0,0,0);
    //background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 47%, rgba(0,0,0,0) 100%);
    bottom: 0px;
    color: $white;
    font-family: $mondo-font;
    font-size: rem-calc(22);
    padding: 20px 0px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @include breakpoint(medium) {
      padding-top:40px;
      font-size: rem-calc(45);
      text-align: left;
    }

    @include breakpoint(large) {
      font-size: rem-calc(55);
      padding: 20px 40px;
    }

    &.black{
       color: $black;
     }
  }
}