@charset 'utf-8';

@font-face {
  font-family: 'Mondosans';
  src:
    url('/assets/fonts/MondoSansV04-Regular-webfont.woff2') format('woff2'),
    url('/assets/fonts/MondoSansV04-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'fontawesome/font-awesome';

//$output-bourbon-deprecation-warnings: false !default;
//@import 'bourbon';

@import 'components/rootvars';
@import 'components/maincontent';
@import 'components/boxcrumb';
@import 'components/image';
@import 'components/navbar';
@import 'components/sectie';
@import 'components/hero';
@import 'components/offcanvas';
@import 'components/layout';
@import 'components/splash';
@import 'components/type';
@import 'components/blog';
@import 'components/blokrow';
@import 'components/browsersynch';
@import 'components/footer';
@import 'components/sidenav';
@import 'components/speellijst';
@import 'components/memberlogin';
@import 'components/animate';
@import 'components/pageNav';
@import 'components/sectie';
@import 'components/paginarij';
@import 'components/richimage';

@import 'glightbox';
