/*  scss file  */

.navbar {
  background-color: $eom-navbar-background;
  height: map-get($eom-navbar-heights, small);
  width: 100%;
  position: absolute;
  top: 0px;
  transition: height 0.5s;
  z-index: 60;

  .logo-row {
    @include grid-column(9);
    padding-top: rem-calc(14);
  }

  .menu-row {
    @include show-for(medium);
  }

  .toggle-row {
    display: block;

    @include hide-for(medium);
  }

  h1 {
    font-size: 2rem;
  }

  @include breakpoint(medium) {
    height: map-get($eom-navbar-heights, medium);

    &.small {
      height: 80px;
    }

    .logo-row {
      @include grid-column(3);
      padding-top: rem-calc(18);
    }

    .menu-row {
    }

    .toggle-row {
      @include grid-column(1);
    }
  }

  @include breakpoint(large) {
    height: map-get($eom-navbar-heights, large);

    &.small {
      height: 80px;
    }

    .logo-row {
      @include grid-column(3);
      padding-top: rem-calc(18);
    }

    .menu-row {
    }

    .toggle-row {
      @include grid-column(1);
    }
  }

  .responsive-menu-toggle {
    color: var(--mondorood);
    font-size: 2rem;
    cursor: pointer;
    // margin: auto 0;
    //padding: 7px 10px;
  }
}

.logo {
  background: url("/assets/eom/img/eom-logo-mobile.png") no-repeat;
  width: 100%;
  height: 40px;
  background-size: contain;

  @include breakpoint(medium) {
    background-image: url("/assets/eom/img/eom-logo.png");
    width: 100%;
    height: 80px;
  }

  @include breakpoint(large) {
    background-image: url("/assets/eom/img/eom-logo.png");
    width: 100%;
    height: 100px;
  }
}

.mainmenu {
  .menu {
    li a {
      color: var(--mondorood);
      font-family: $mondo-font;
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    li.active a {
      color: var(--mondozwart);
    }
  }
}

.top-bar {
  font-family: $mondo-font;
  margin: 0 0 0 0;
  height: 45px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: top 0.4s ease-in-out;
}

.nav-up {
  top: -50px;
}

.mobilelogo {
  font-family: $mondo-font;
  text-transform: uppercase;
  font-size: 2rem;
}
