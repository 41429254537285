.lightbox {
  position: relative;

  &::after {
    background-repeat: no-repeat;
    top: 5px;
    content: " ";
    height: 20px;
    position: absolute;
    right: 5px;
    width: 20px;
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17'%3E%3Cstyle%3E.st0{opacity:.5;fill:%23fff}.st1{fill:none;stroke:%231f1d21;stroke-linecap:square}%3C/style%3E%3Ccircle class='st0' cx='6.5' cy='6.4' r='6.1'/%3E%3Cpath d='M10.5 11.3c-2.6 2-6.3 1.9-8.6-.4-2.5-2.5-2.5-6.5.1-9s6.6-2.5 9.1-.1c2.4 2.4 2.5 6.2.2 8.8l5.8 5.7-.9.7-5.7-5.7zm-8-1.1c2.2 2.1 5.7 2.1 7.8 0s2.2-5.6 0-7.7-5.7-2.1-7.8 0-2.1 5.6 0 7.7z'/%3E%3Cpath id='Line-2' class='st1' d='M4 6.5h5'/%3E%3Cpath id='Line' class='st1' d='M6.5 4v5'/%3E%3C/svg%3E");
  }
}
