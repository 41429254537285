/*  scss file  */

html,body{
    height:100%;
}
#bg {
    z-index: -3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}
.menuspacer{
    height:50px;
}

.toprow{
    margin-top:70px;
}

.verzamelrow {
    @include clearfix();
    margin: 0 0 rem-calc(5) 0;
    padding: rem-calc(5) 0 rem-calc(20) 0;
    background: url("/assets/images/lijn-grijs.png") right bottom no-repeat;
    background-size: 100%;

    h3 {
        padding: 0;
        margin: 0;
    }
}

