/*  scss file  */

.blog {
  .sidemenu {
    ul {
      margin-left: 0;
    }

    li {
      // list-style-image: url("images/bullet.gif");
      list-style: none;
      margin: 1rem 0 0 0;
      line-height: 1.1rem;
    }

    .datum {
      font-size: rem-calc(12);
    }

    .accordion-item {
      a {
        font-weight: bold;
      }
    }
  }

  .post {
    border-bottom: 1px solid $mondo-lichtgrijs;
    padding: 0 0 20px 0;
  }

  .post-info {
    color: var(--mondozwart);
    font-size: rem-calc(16);
    margin: rem-calc(-8) 0 rem-calc(8) 0;
    font-weight: 700;
    line-height: rem-calc(16);
    padding: 0;
  }

  .abstract {
    color: var(--mondorood);
    font-size: rem-calc(32);
    font-family: $mondo-font;
    text-transform: uppercase;
    line-height: rem-calc(32);
    padding: 0;
    margin-bottom: 0;
  }

  .tl-tag {
    font-weight: bold;
  }
  .month {
    font-weight: bold;
  }
  .year {
    font-weight: bold;
  }
}

.nextprevlinks {
  padding: 10px 0;
}

.arrowlink {
  background-size: 10%;
  background-repeat: no-repeat;
  @include breakpoint(medium) {
    background-size: 7%;
  }
  @include breakpoint(large) {
    background-size: 3%;
  }
}

.nextlink {
  background-image: url("/assets/images/next.gif");
  background-position: right top;
  padding: 0 30px 0 0;

  li {
    list-style: none;
    text-align: right;
  }
}

.prevlink {
  background-image: url("/assets/images/prev.gif");
  background-position: left top;
  padding: 0 0 0 30px;

  li {
    list-style: none;
    text-align: left;
  }
}

.entry {
  @include clearfix();
  .rowimg {
    margin: 0 20px 0 0;
    float: left;
  }
}

.paging {
  margin: 0;
  padding: 10px;
  width: 100%;
}

.paging ul {
  list-style: none;
  margin: 0;
  padding: 0 0;
}

.paging li a {
  list-style: none;
  float: left;
  margin: 0 3px 0 0;
  padding: 5px 0;
  width: 30px;
  text-align: center;

  &.active {
    color: $white;
    background-color: var(--mondorood);
  }
}

.paging li a:hover {
  background-color: #f4f4f4;
}
